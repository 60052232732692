<template>
  <!-- TradingView Widget BEGIN -->
  <div class="tradingview-widget-container">
    <div class="tradingview-widget-container__widget"></div>
<!--    <div class="tradingview-widget-copyright"></div>-->
  </div>
  <!-- TradingView Widget END -->
</template>

<script>
export default {
  name: "tradingViewWidget",
  data() {
    return {
      symbols: [
        {
          "proName": "FX_IDC:EURUSD",
          "title": "EUR/USD"
        },
        {
          "proName": "BITSTAMP:BTCUSD",
          "title": "Bitcoin"
        },
        {
          "proName": "BITSTAMP:ETHUSD",
          "title": "Ethereum"
        },
        {
          "proName": "BITSTAMP:ETHUSD",
          "title": "Ethereum"
        },
        {
          "proName": "BITSTAMP:ETHUSD",
          "title": "Ethereum"
        }
      ],
      colorTheme: 'light',
      isTransparent: false,
      displayMode: 'adaptive',
      locale: 'en',
      showSymbolLogo: true
    }
  },
  mounted() {
    const widgetPlaceholder = document.getElementsByClassName('tradingview-widget-container')[0];
    widgetPlaceholder.replaceChildren(); // empty placeholder
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js'
    script.async = true;
    script.innerHTML = JSON.stringify({
      "symbols": this.symbols,
      "isTransparent": this.isTransparent,
      "displayMode": this.displayMode,
      "locale": this.locale,
      "colorTheme": this.colorTheme,
      "showSymbolLogo": this.showSymbolLogo
    });
    widgetPlaceholder.appendChild(script);
  }
}
</script>

<style scoped>
.tradingview-widget-container {
  height: 140px;
}

</style>